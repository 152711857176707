import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';

const Chart = ({chartData}) => {
    const chartContainerRef = useRef(null);
    const chartRef = useRef(null);

    useEffect(() => {
        chartRef.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 300,
            timeScale: {
                timeVisible: true,
                secondsVisible: true,
                rightOffset: 12,
                fixLeftEdge: true,
                borderColor: '#373737',
                tickMarkFormatter: (time, tickMarkType, locale) => {
                    const date = new Date(time * 1000);
                    const hours = date.getHours().toString().padStart(2, '0');
                    const minutes = date.getMinutes().toString().padStart(2, '0');
                    // const day = date.getDate().toString().padStart(2, '0');
                    // const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                    // const year = date.getFullYear().toString();

                    return `${hours}:${minutes}`;
                },
            },
            layout: {
                background: { color: '#0d0d0f', backgroundColor: 'red' },
                textColor: '#fff',
                fontSize: 10,
            },
            grid: {
                vertLines: {                  
                    visible: false,
                },
                horzLines: { 
                    color: 'rgb(33 33 35 / 60%)' 
                },
            },

        });

        const lineSeries = chartRef.current.addLineSeries({
            lineWidth: 3,
            color: 'rgb(208, 65, 64)',
            priceFormat: {
                type: 'custom',
                minMove: 0.0000001,
                formatter: (price) => price.toPrecision(2),
                borderColor: '#373737',
            },
        });

        lineSeries.setData(chartData);
        
        chartRef.current.timeScale().fitContent();

        const handleResize = () => {
            chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chartRef.current.remove();
        };
    }, []);

    return <div ref={chartContainerRef} style={{ position: 'relative', width: '100%', height: '300px' }} />;
};

export default Chart;
