import PropTypes from "prop-types";
import React, {useEffect, useState, useRef} from "react";
import {
  Container
} from "reactstrap";
import axios from "axios";
import './pagination.css'
import './styles.css'
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from '../../components/Common/TableContainer'

//i18n
import { withTranslation } from "react-i18next";

import {
  createColumnHelper
} from '@tanstack/react-table';

import www from './img/www.png';
import tw from './img/tw.png';
import tele from './img/tel.png'

//tooltip
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import {
  Modal,
} from "reactstrap";

//chart
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
import Chart from './Chart';
import BarLoader from "react-spinners/ClipLoader";


import demo from './demo.json'
import CountdownTimer from "./counter";
//redux
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/actions";

import tweeterSVG from './img/tweeter.svg'
import telegramSVG from './img/telegram.svg'
import wwwSVG from './img/web.svg'
import copy from './img/copy.svg'
import sun from './img/sun.svg'
import tronscan from './img/tronscan.svg'

import { FaPlay } from "react-icons/fa6";
import { FaCircleInfo } from "react-icons/fa6";
import { SiDevdotto } from "react-icons/si";
import { ImInfo } from "react-icons/im";

const Bondinglive = props => {
  const dispatch = useDispatch();

  const [baseData, setBaseData] = useState(null);
  const [modal_standard, setmodal_standard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal_data, setmodal_data] = useState([]);
  const columnHelper = createColumnHelper();
  const [filter_bonding_progres, set_filter_bonding_progres] = useState('')
  const [filter_listed_since, set_filter_listed_since] = useState('')


  const bonding_progress_Ref = useRef();
  const listed_since_Ref = useRef();

  const countdownRef = useRef();
  const [countdown, setCountdown] = useState(10);
  const [isRunning, setIsRunning] = useState(true);

  document.title = "Bonding Live";

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    if(modal_standard){
      setmodal_data([])
    }
  };

  const handleClickBuy = (tokenAdress) => {
    const url = 'https://sunpump.meme/token/' + tokenAdress;
    window.open(url, '_blank');
  };

  const handleClickCa = (tokenAdress) => {
    const url = 'https://tronscan.org/#/token20/' + tokenAdress;
    window.open(url, '_blank');
  };

  const handleRedirect = (url) => {
    window.open(url, '_blank');  
  };

  function formatNumber(num) {
    const str = num.toString();
    const firstNonZeroIndex = str.indexOf('0.') + 2 + str.slice(2).match(/[^0]/).index;
    const zerosCount = firstNonZeroIndex - 2 - 1;
  
    if (zerosCount >= 2) {
      let significantDigits = str.slice(firstNonZeroIndex, firstNonZeroIndex + 4);

      if (str.length > firstNonZeroIndex + 4) {
        const roundingFactor = Math.pow(10, 4);
        significantDigits = Math.round(parseFloat("0." + significantDigits) * roundingFactor) / roundingFactor;
        significantDigits = significantDigits.toString().slice(2);
      }
  
      const formatted = (
        <>
          0.0<span className="index-zero">{zerosCount}</span>{significantDigits}
        </>
      );
      return formatted;
    } else {
      return str;
    }
  };

  function sortAndGetTop10(arr) {
    const top10 = arr
      .sort((a, b) => b.amount_percent - a.amount_percent)
      .slice(0, 10);
    return top10;
  };

  function sortAndGetTop10_2(arr) {
    const creatorAddress = arr.token.creator_address;
    const top10 = arr.data.holders_list
      .map(o => {
        o.creator = o.wallet_address === creatorAddress;
        return o;
      })
      .sort((a, b) => b.amount_percent - a.amount_percent)
      .slice(0, 10);
    return top10;
  };


  function formatNumberPrice(numberString) {
    let number = parseFloat(numberString);
    let roundedNumber = number.toFixed(2);
    let parts = roundedNumber.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  };

  function transformArray(inputArray) {
    const transformedArray = inputArray.map(item => {
        return {
            time: moment(item[0]).valueOf() / 1000,
            value: parseFloat(item[1])
        };
    });
    transformedArray.sort((a, b) => a.time - b.time);
    const uniqueArray = transformedArray.filter((item, index, self) => {
        return index === 0 || item.time !== self[index - 1].time;
    });
    return uniqueArray;
  }

  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const hoursString = hours.toString().padStart(2, '0');
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const minutesString = minutes.toString().padStart(2, '0');
    const remainingSecondsFinal = remainingSeconds % 60;
    const secondsString = remainingSecondsFinal.toFixed(0).padStart(2, '0');
  
    return `${hoursString}:${minutesString}:${secondsString}`;
  }

  const copyToClipboard = (address) => {
    navigator.clipboard.writeText(address)
      .catch((err) => {
        console.error('Błąd podczas kopiowania: ', err);
      });
  };

  const columns = [
    columnHelper.accessor(row => row.token.logo, {
      id: 'logo',
      cell: info => {
        return (
          <div style={{maxWidth: '40px'}}>
            <div>{info.cell.row.original.token.has_image 
            ? <img src={`//api.degencave.com/images/256_${info.cell.row.original.token.contract_address}.png`} width={30} height={30} style={{borderRadius: '30px'}}/> 
            : <div className='noneImg'>N/A</div>}</div>
          </div>

        )
      },
      header: () => <span style={{maxWidth: '40px'}}></span>,
      footer: info => info.column.id,
      size: 40,
      enableColumnFilter: false
    }),
    
    columnHelper.accessor(row => row.token.contract_name, {
      id: 'contract_name',
      cell: info => {
        return (
          <div>
            <div className="token-names">
              <b className="name-row btns">
                <span style={{marginRight: '3px'}}>{info.cell.row.original.token.contract_short_name}</span>

                {<button
                  className={'table-btn'} 
                  onClick={()=>copyToClipboard(info.cell.row.original.token.contract_address)}
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'Copy Token Contract Address'} // contract_address
                >
                  <img src={copy} alt=""/>
                </button>}

                {<button 
                  className={'table-btn'} 
                  onClick={()=>handleClickBuy(info.cell.row.original.token.contract_address)} 
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'Buy'}
                >
                  <img src={sun} alt=""/>
                </button>}

                {<button 
                  className={'table-btn'} 
                  onClick={()=>handleClickCa(info.cell.row.original.token.contract_address)} 
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'TronScan'}
                >
                  <img src={tronscan} width={12} alt=""/>
                </button>}
              </b>
              <div className="ticker-row btns">
                <span className="ticker">{info.cell.row.original.token.contract_name}</span>

                {info.cell.row.original.token.twitter_url && 
                  <button 
                    onClick={()=>handleRedirect(info.cell.row.original.token.twitter_url)}  
                    className={'table-btn'} 
                    style={{background:'transparent', padding: 0}}
                    data-tooltip-id="create_timestamp" data-tooltip-content={'X'}
                  >
                    <img src={tweeterSVG} alt=""/>
                  </button>
                }
                {info.cell.row.original.token.website_url && 
                  <button 
                    onClick={()=>handleRedirect(info.cell.row.original.token.website_url)} 
                    className={'table-btn'} 
                    style={{background:'transparent', padding: 0}}
                    data-tooltip-id="create_timestamp"
                    data-tooltip-content={'Website'}
                  >
                    <img src={wwwSVG} alt=""/>
                  </button>
                }
                {info.cell.row.original.token.telegram_url && 
                  <button 
                    onClick={(e)=>{
                    handleRedirect(info.cell.row.original.token.telegram_url)
                  }}  
                  className={'table-btn'} 
                  style={{background:'transparent', padding: 0}}
                  data-tooltip-id="create_timestamp"
                  data-tooltip-content={'Telegram'}
                  >
                    <img src={telegramSVG} alt=""/>
                  </button>
                }

              </div>
            </div>
          </div>

        )
      },
      header: ({column}) => <span
        className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
        onClick={column.getToggleSortingHandler()}
        style={{textAlign: 'left'}}
      >Name</span>,
      enableColumnFilter: false      
    }),


    columnHelper.accessor(row => row.data.last_since, {
      id: 'last_since',
      cell: info => {
        return (
          <div className='to-right' style={{position: 'relative'}}>
            <div data-tooltip-id="create_timestamp" data-tooltip-content={info.cell.row.original.token.create_timestamp} style={{position: 'absolute'}}>
              {secondsToTime(info.cell.row.original.token.create_time_elapsed)}
            </div>
          </div>
        )
      },
      header: ({column}) => (
        <span 
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Start
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.last_price_usd, {
      id: 'last_price_usd',
      cell: info => {
        return (
          <div 
          className='to-right btns'>
            <button
              className="table-btn"
              onClick={() => {
                tog_standard();
                setmodal_data(info.cell.row.original.data.prices)
              }}
            >
              {
                info.cell.row.original.data.last_price_usd === null 
                ? '-' 
                : formatNumber(info.cell.row.original.data.last_price_usd)
              }
            </button>
            
        </div>
        )
      },
      header: ({column}) => (
        <span
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Price<br/>
          <span className="small">USD</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    // columnHelper.accessor(row => row.data.last_price, {
    //   id: 'last_price',
    //   cell: info => {
    //     return (
    //       <div className='to-right'>
    //         {
    //           info.cell.row.original.data.last_price === null 
    //           ? '-' 
    //           : formatNumber(info.cell.row.original.data.last_price)
    //         }
    //       </div>
    //     )
    //   },
    //   header: () => (
    //     <span className="header-title">
    //       Price<br/>
    //       <span className="small">TRX</span>
    //     </span>
    //   ),
    //   footer: info => info.column.id,
    //   enableColumnFilter: false      
    // }),

    columnHelper.accessor(row => row.data.market_cap_usd, {
      id: 'market_cap',
      cell: info => {
        return (
          <div className='to-right'>
            {
              info.cell.row.original.data.market_cap_usd 
              ? `$${(parseFloat(info.cell.row.original.data.market_cap_usd)/1000).toFixed(1)}k` 
              : '-'
            }
          </div>

        )
      },
      header: ({column}) => (
        <span 
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Mcap<br/>
          <span className="small">USD</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false, 
    }),

    // columnHelper.accessor(row => row.data.market_cap_trx, {
    //   id: 'market_cap_trx',
    //   cell: info => {
    //     return (
    //       <div className='to-right'>
    //         {
    //           info.cell.row.original.data.market_cap_usd 
    //           ? `${parseFloat(info.cell.row.original.data.market_cap_trx).toFixed(1)}` 
    //           : '-'
    //         }
    //       </div>

    //     )
    //   },
    //   header: () => (
    //     <span className="header-title">
    //       MarketCap<br/>
    //       <span className="small">TRX</span>
    //     </span>
    //   ),
    //   footer: info => info.column.id,
    //   enableColumnFilter: false      
    // }),

    columnHelper.accessor(row => row.data.bonding_progress, {
      id: 'bonding_progress',
      cell: info => {
        return (
          <div className={info.cell.row.original.token.launch_pending_event_time === null ? "to-right" : "complete"}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
              {info.cell.row.original.token.launch_pending_event_time === null ?
                (
                  <>
                    <div className="progres-bar">
                      <div className="point" style={{left: `${Math.min(parseFloat(info.cell.row.original.data.bonding_progress).toFixed(0), 100)}px`}}></div>
                    </div>
                    <div className="procent">
                      {parseFloat(info.cell.row.original.data.bonding_progress).toFixed(1)}% 
                    </div>                      
                  </>
                )
              :
                (<div className="bonding-complete">
                  Bonding done: {moment(info.cell.row.original.token.launch_pending_event_time).format('mm')} min.
                </div>)
              }
            </div>
          </div>

        )
      },
      header: ({column}) => (
        <span
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Bonding<br/>
          <span className="small">Progress completed</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false, 
    }),

    columnHelper.accessor(row => row.data.swaps_last_5_minutes, {
      id: 'swaps_last_5_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {info.cell.row.original.data.swaps_last_5_minutes}
          </div>

        )
      },
      header: ({column}) => (
        <span
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Tx<br/>
          <span className="small">5 min.</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.swaps, {
      id: 'swaps',
      cell: info => {
        return (
          <div className='to-right'>
            {info.cell.row.original.data.swaps}
          </div>

        )
      },
      header: ({column}) => (
        <span 
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Tx<br/>
          <span className="small">Total</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.volume_last_5_minutes, {
      id: 'volume_last_5_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            ${(parseFloat(info.cell.row.original.data.volume_last_5_minutes)/1000).toFixed(1)}k
          </div>

        )
      },
      header: ({column}) => (
        <span 
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Vol.<br/>
          <span className="small">5 min.</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.volume_usd, {
      id: 'volume',
      cell: info => {
        return (
          <div className='to-right'>
            {/* {info.cell.row.original.data.volume} */}
            ${(parseFloat(info.cell.row.original.data.volume_usd)/1000).toFixed(1)}k
          </div>
        )
      },
      header: ({column}) => (
        <span 
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Vol.<br/>
          <span className="small">Total</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.holders, {
      id: 'holders',
      cell: info => {
        let color = ''
        let bg = ''
        const procent = sortAndGetTop10(info.cell.row.original.data.holders_list)[0]?.amount_percent
        const procentInt = procent ? parseFloat(procent).toFixed(0) : null

        if(procentInt === null){
          bg = "transparent"
          color = '#000'
        }else if(procentInt <= 5){
          bg = '#479a70';
          color = '#fff';
        }else if(procentInt > 5 && procentInt <= 10){
          bg = '#d8b744';
          color = '#000';
        }else if(procentInt > 10 && procentInt <= 20){
          bg = '#da7124';
          color = '#fff';
        }else if(procentInt > 20){
          bg = '#dc1f1d';
          color = '#fff';
        }

        return (
          <div 
            className='to-right btns'>
              <button
                className="table-btn"
                onClick={() => {
                  if(procentInt !== null){
                    tog_standard();
                    setmodal_data(info.cell.row.original)                    
                  }
                }}
                style={{backgroundColor: bg, color: color, minWidth: '35px'}}
              >
                {info.cell.row.original.data.num_holders}
              </button>
              
          </div>
        )
      },
      header: ({column}) => (
        <span 
          style={{ textAlign: 'right', display: 'block', cursor: 'pointer' }}
        >
          <span style={{marginRight: '5px'}}>Holders</span> 
          <ImInfo
            size={15}
            color={"#5c6068"}
            data-tooltip-id="htmltooltip"
            data-tooltip-html={'<div style="text-align: left">The colors in the HOLDERS column indicate the largest token holder. <br/> - Green represents ownership of less than 5%, <br/>- Yellow represents 5% to 10%, <br/>- Orange represents 10% to 20%, <br/>- Red represents more than 20% ownership.</div>'}
          />
          <Tooltip
            id="htmltooltip"
            place={'bottom'}
          />
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
      enableSorting: false      
    }),

    // columnHelper.accessor(row => row.data.bonding_num_trx, {
    //   id: 'bonding_num_trx',
    //   cell: info => {
    //     return (
    //       <div className='to-right'>
    //         {parseFloat(info.cell.row.original.data.bonding_num_trx).toFixed(2)}
    //       </div>

    //     )
    //   },
    //   header: () => (
    //     <span className="header-title">
    //       TRX<br/>
    //       <span className="small">in bonding pool</span>
    //     </span>
    //   ),
    //   footer: info => info.column.id,
    //   enableColumnFilter: false      
    // }),

    columnHelper.accessor(row => row.data.num_created_tokens_by_wallet, {
      id: 'num_created_tokens_by_wallet',
      cell: info => {
        return (
          <div className='to-right'>
            {info.cell.row.original.data.num_created_tokens_by_wallet}
          </div>

        )
      },
      header: ({column}) => (
        <span 
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Tokens<br/>
          <span className="small">Created</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.buysell_last_1_minutes, {
      id: 'buysell_last_1_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.buysell_last_1_minutes)/1000).toFixed(2)}k
          </div>
        )
      },
      header: ({ column }) => (
        <div className="column-header-row">
          {/* <div className="sortArrow">
            <span>
              {column.getIsSorted() ? ( // Dodanie wskaźnika sortowania
                column.getIsSorted() === 'desc' ? ' ↓' : ' ↑'
              ) : ''}            
            </span>   
          </div>  */}
          <span
            className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
            onClick={column.getToggleSortingHandler()} // Ustawienie handlera kliknięcia
          >
            Buy/Sell<br/>
            <span className="small">1 min.</span>
          </span>      
        </div>

        
      ),
      footer: info => info.column.id,
      enableColumnFilter: false,
    }),

    columnHelper.accessor(row => row.data.buysell_last_3_minutes, {
      id: 'buysell_last_3_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.buysell_last_3_minutes)/1000).toFixed(2)}k
          </div>
        )
      },
      header: ({ column }) => (
        <div className="column-header-row">
          {/* <div className="sortArrow">
            <span>
              {column.getIsSorted() ? ( // Dodanie wskaźnika sortowania
                column.getIsSorted() === 'desc' ? ' ↓' : ' ↑'
              ) : ''}            
            </span>   
          </div>  */}
          <span 
            className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
            onClick={column.getToggleSortingHandler()}
          >
            Buy/Sell<br/>
            <span className="small">3 min.</span>
          </span>
        </div>

      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.buysell_last_5_minutes, {
      id: 'buysell_last_5_minutes',
      cell: info => {
        return (
          <div className='to-right'>
            {(parseFloat(info.cell.row.original.data.buysell_last_5_minutes)/1000).toFixed(2)}k
          </div>
        )
      },
      header: ({ column }) => (
        <span 
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Buy/Sell<br/>
          <span className="small">5 min.</span>
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.num_with_same_name, {
      id: 'num_with_same_name',
      cell: info => {
        return (
          <div className='to-right'>
            {info.cell.row.original.data.num_with_same_name} ({info.cell.row.original.data.num_bonded_with_same_name})
          </div>
        )
      },
      header: ({ column }) => (
        <span
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          Doubles
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    columnHelper.accessor(row => row.data.owner.percent, {
      id: 'owner_percent',
      cell: info => {
        return (
          <div className='to-right btns'>
            {parseFloat(info.cell.row.original.data.owner.percent).toFixed(2)}%
            {<button
              className={'table-btn'} 
              onClick={()=>copyToClipboard(info.cell.row.original.token.creator_address)}
              style={{background:'transparent', padding: 0}}
              data-tooltip-id="create_timestamp"
              data-tooltip-content={'Copy Token Creator Address'}
            >
              <img src={copy} alt=""/>
            </button>}

          </div>
        )
      },
      header: ({ column }) => (
        <span
          className={`header-title ${column.getIsSorted() ? (column.getIsSorted() === 'desc' ? 'sort-desc' : 'sort-asc') : ''}`}
          onClick={column.getToggleSortingHandler()}
        >
          DEV
        </span>
      ),
      footer: info => info.column.id,
      enableColumnFilter: false      
    }),

    // columnHelper.accessor(row => row.data.num_bonded_with_same_name, {
    //   id: 'num_bonded_with_same_name',
    //   cell: info => {
    //     return (
    //       <div className='to-right'>
    //         {info.cell.row.original.data.num_bonded_with_same_name}
    //       </div>
    //     )
    //   },
    //   header: () => (
    //     <span className="header-title">
    //       Doubles<br/>
    //       <span className="small">Bonded</span>
    //     </span>
    //   ),
    //   footer: info => info.column.id,
    //   enableColumnFilter: false      
    // }),

    // columnHelper.accessor(row => row.token.creator_address, {
    //   id: 'creator_address',
    //   cell: info => {
    //     return (
    //       <div className='to-right' style={{fontSize: '9px'}}>
    //         {info.cell.row.original.token.creator_address}
    //       </div>
    //     )
    //   },
    //   header: () => (
    //     <span className="header-title">
    //       Creator <br/>
    //       <span className="small">Address</span>
    //     </span>
    //   ),
    //   footer: info => info.column.id,
    //   enableColumnFilter: false      
    // }),


    // columnHelper.accessor(row => row.data.infos, {
    //   id: 'infos',
    //   cell: info => {
    //     return (
    //       <div className='to-right btns'>
    //         {<button className={'table-btn'} onClick={()=>handleClickBuy(info.cell.row.original.token.contract_address)}>Buy</button>}
    //         {<button className={'table-btn'} onClick={()=>handleClickCa(info.cell.row.original.token.contract_address)}>CA</button>}
    //       </div>
    //     )
    //   },
    //   header: () => <span style={{ textAlign: 'right', display: 'block' }}></span>,
    //   footer: info => info.column.id,
    //   enableColumnFilter: false      
    // }),

  ];
  
  const columns_holders_list = [
    columnHelper.accessor(row => row.amount, {
      id: 'amount',
      cell: info => {
        return (
          <div>
            {formatNumberPrice(info.cell.row.original.amount)}
          </div>
        )
      },
      header: () => <span>Amount</span>,
      enableColumnFilter: false      
    }),
    columnHelper.accessor(row => row.amount_percent, {
      id: 'amount_percent',
      cell: info => {
        return (
          <div className='to-right'>
            {parseFloat(info.cell.row.original.amount_percent).toFixed(2)}%
          </div>

        )
      },
      header: () => <span style={{ textAlign: 'right', display: 'block' }}>Percent</span>,
      enableColumnFilter: false      
    }),
    columnHelper.accessor(row => row.wallet_address, {
      id: 'wallet_address',
      cell: info => {
        return (
          <div className='to-right'>
            
            {info.cell.row.original.creator 
              ? <span data-tooltip-id="dev" data-tooltip-content={'Token Creator'}><SiDevdotto size={30} color={'#2d9863'} style={{marginRight: '10px'}}/> </span>
              : ''
            }
            {info.cell.row.original.wallet_address}
            <Tooltip
              id="dev"
              place={'top'}
            />
          </div>
        )
      },
      header: () => <span style={{ textAlign: 'right', display: 'block' }}>Address</span>,
      enableColumnFilter: false      
    }),
  ]


  const [filteredData, setfilteredData] = useState([])
  const authToken = sessionStorage.getItem('authToken');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const response = await axios.get('https://api.degencave.com/api/dashboard/latest', {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': authToken
          }
        });
        setfilteredData(response.data.coins)
        setBaseData(response.data.coins);

        // setBaseData(demo.coins)
        // setfilteredData(demo.coins)

        setLoading(false)
      } catch (error) {
        console.error(error);
        setLoading(false)
      }
    };

    fetchData();

    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        fetchData();
        setCountdown(10); // Resetuj licznik do 10 sekund po każdym pobraniu danych
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);



  const toggleTimer = () => {
    setIsRunning(!isRunning);
  };

  const filterDatasFun = (data) => {
      const filteredDataTmp = data
      ? data.filter(item => {
          const bondingProgress = parseFloat(item.data.bonding_progress);
          const createTimeElapsed = parseFloat(item.token.create_time_elapsed);

          return bondingProgress > bonding_progress_Ref.current.value &&
                createTimeElapsed >= listed_since_Ref.current.value * 60;
        })
      : [];

      setfilteredData(filteredDataTmp)       

  }

  useEffect(() => {
    if(baseData?.length > 0){
      filterDatasFun(baseData)
    }
  }, [filter_bonding_progres, filter_listed_since, baseData]);


  useEffect(()=>{
    if(authToken === null){
      dispatch(logoutUser());
      window.location.href = '/login';
    }
  },[])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="settings">
            <div className="filter">
              <input className="filter-search" ref={listed_since_Ref} type="number" placeholder="Listed since > X min." value={filter_listed_since} onChange={e => set_filter_listed_since(e.target.value)}/>
              <input className="filter-search" ref={bonding_progress_Ref} type="number" placeholder="Bonding > X %" value={filter_bonding_progres} onChange={e => set_filter_bonding_progres(e.target.value)}/>
            </div>

            
            <div className="refresh">
              <button onClick={toggleTimer} className="btnSimple" style={isRunning ? {color: '#2d9863'} : {color: '#666666'}}>
                <FaPlay size={14}/>
              </button>
              <div className="labelBTN">refresh:</div>
              <div className="refresh-value" style={isRunning ? {color: '#ffffff'} : {color: '#666666'}}>
                {/* {countdown} */}
                <CountdownTimer isRunning={isRunning}/>
              </div>
            </div>
          </div>
          <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
            size="lg"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {modal_data?.length > 0 && modal_data[0].amount ? 'Holders list' : 'Price chart'}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {modal_data.data?.holders_list?.length > 0 &&
                modal_data.data?.holders_list[0].amount 
                ? 
                  <TableContainer
                    data={sortAndGetTop10_2(modal_data)}
                    columns={columns_holders_list}
                  />
                : (
                  <Chart chartData={transformArray(modal_data)}/>
                )
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
                style={{background: '#212227', border:0}}
              >
                Close
              </button>
            </div>
          </Modal>


          <TableContainer
            data={filteredData || []}
            columns={columns}
            isPagination={true}
            paginationWrapper={'pagination'}
          />
          
        </Container>
      </div>
      <Tooltip id="create_timestamp" />
    </React.Fragment>
  );
};

Bondinglive.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Bondinglive);
