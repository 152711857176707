import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.svg";
import scanmeme from './img/scanmeme.svg'
import './login.css'
import logo_bg from './img/login_bg.jpg'
import logo_2 from './img/logo.png'
import logo_color from './img/logo-color.png'
import toast, { Toaster } from 'react-hot-toast';
import {versions} from '../../components/HorizontalLayout/version'


const loadTronWeb = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/tronweb/dist/TronWeb.js';
  script.async = true;
  document.body.appendChild(script);
};

const Login = props => {
  const [errorMessage, setErrorMessage] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [authToken, setAuthToken] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  //meta title
  document.title = "Login | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "admin@themesbrand.com" || '',
      password: "123456" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });


  const LoginProperties = createSelector(
    (state) => state.Login,
    (login) => ({
      error: login.error
    })
  );

  const {
    error
  } = useSelector(LoginProperties);


  useEffect(() => {
    loadTronWeb();
  }, []);

  useEffect(() => {
    if(errorMessage === "User rejected the signature request."){
      setWalletAddress('')
      setAuthToken(null)
      setIsConnected(false)
    }
  }, [errorMessage]);

  const generateSignedMessage = async (walletAddress) => {
    try {
      const ts = new Date().getTime();
      const app = 'degencave.com';
      const message = JSON.stringify({ walletAddress, ts, app });

      const signature = await window.tronWeb.trx.signMessageV2(message);
      const token = 'Auth2 ' + signature + '.' + window.tronWeb.toHex(message);
      setAuthToken(token);

      return signature;
    } catch (error) {
      setErrorMessage('User rejected the signature request.');
    }
  };

  const handleWalletConnected = async (address) => {
    setWalletAddress(address);
    setIsConnected(true);
    await generateSignedMessage(address);
  };

  const connectWallet = async () => {
    if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
      handleWalletConnected(window.tronWeb.defaultAddress.base58);
    } else if (window && window.tronLink) {
      window.tronLink.request({ method: 'tron_requestAccounts' });
      toast.error('Please open your TronLink and choose the appropriate wallet to connect.');
    }
  };



  const fireRequest = async () => {
    const url = 'https://api.degencave.com/api/users/validate_auth/';
    // const url = '/api/users/validate_auth/';
    let headers = {
      'Content-Type': 'application/json'
    };

    if (authToken) {
      headers['Authorization'] = authToken;
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: headers
      });
    
      if (response.status === 401) {
        toast.error('Authorization incorrect!');
        setWalletAddress('')
        setAuthToken(null)
        setIsConnected(false)
        return; // Exit early since we handled the error
      }
    
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    
      if (response.status === 200) {
        toast.success('Authorization correct!');
        sessionStorage.setItem('authToken', authToken);
        setTimeout(() => {
          validation.handleSubmit();
        }, 2000);
      }
    
      const data = await response.json();
      // console.log('Data:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <React.Fragment>
      <div className="login">
        <Toaster />
        <div className="login-column">
          <div className="logo">
            <img src={scanmeme} alt="" width={400}/>
            <span className="hero">
              <span>Stay Ahead,</span>
              <span>Spot Gems,</span>
              <span>and Avoid Rugs!</span>
            </span>
          </div>
          <div className="overflow-hidden login-contnent">
            <CardBody className="pt-0">
              <div>
                <Link to="/" className="logo-light-element">
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img
                        src={logo}
                        alt=""
                        className="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="p-2">
                <div
                  className="form-horizontal"
                >
                  {error ? <Alert color="danger">{error}</Alert> : null}
                  {/* {isToLogin ? <Alert color="danger">Your wallet was not authorized</Alert> : null} */}


                  <div className="mt-3 d-grid" style={{gap: '20px'}}>
                    <button 
                      className="btn btn-primary btn-block btn-login"
                      onClick={connectWallet} 
                      disabled={isConnected}
                    >
                      {isConnected ? 'Wallet Connected' : 'Connect Wallet'}
                    </button>

                    <button
                      className="btn btn-primary btn-block btn-login"
                      onClick={fireRequest}
                      disabled={authToken ? false : true}
                    >
                      Log In
                    </button>
                    
                  </div>

                </div>
              </div>
            </CardBody>
          </div>
          <div className="mt-5 text-center">
            <p>
              © {new Date().getFullYear()} Scan.meme v{versions[0].version}<br/>
              <span className="footer-title">Privacy Policy | Terms of Service</span>
            </p>
          </div>
        </div>       
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
