export const versions = [
  {
    version: "0.48",
    logs: [
      {
        scope: "",
        changes: [
          "Renamed to Scan.Meme: Updated the platform name to \"Scan.Meme\" as part of the first phase of the UI changes.",
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.47",
    logs: [
      {
        scope: "",
        changes: [
          "Holders Tooltip Description Improvement: Enhanced the tooltip description for the Holders column to provide clearer and more detailed information.",
          "Creator Wallet Marking in HOLDERS POP UP: Marked the creator's wallet in the HOLDERS POP UP if it is in the Top 10 holders of the token. Added \"DEV WALLET\" label and color icon."
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.46",
    logs: [
      {
        scope: "",
        changes: [
          "Play/Stop refresh counter",
          "Default Sorting by Buy/Sell (1 minute): The list now defaults to sorting by the latest Buy/Sell transactions within the past minute.",
          "Token Contract Address Copy: Added a \"Copy Token Contract Address\" option next to the token name, allowing users to easily copy the contract address.",
          "DEV Column Enhancement: Introduced a DEV column showing the percentage of tokens held by the meme creator. Added a button for copying the creator's address (Creator Address).",
          "Column Sorting Color: Added color indicators to highlight the sorted column.",
          "Holders Table Tooltip: Added a tooltip to the table header of the Holders column providing additional information.",
          "Fix - you have been logged out because authentication was unsuccessful"
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.45",
    logs: [
      {
        scope: "",
        changes: [
          "A counter measuring the time until the next refresh",
        ],
      },
    ],
    author: "RK",
  },
  {
    version: "0.44d",
    logs: [
      {
        scope: "",
        changes: ["Button Placement Update"],
      },
    ],
    author: "RK",
  },
  {
    version: "0.43",
    logs: [
      {
        scope: "",
        changes: ["Changelog Feature", "You have been logged out because authentication was unsuccessful"],
      },
    ],
    author: "RK",
  },
]