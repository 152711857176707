import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import {versions} from './version'
import {
  Modal,
} from "reactstrap";

const Footer = () => {
  const [modal_standard, setmodal_standard] = useState(false);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} 
              © Scan.meme v<button onClick={tog_standard} style={{background: 'transparent',border: 0, padding: 0}}>{versions[0].version}</button>
            </Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Privacy Policy | Terms of Service
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
            size="lg"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Changelog
              </h5>
              <button
                type="button"
                onClick={() => {
                  setmodal_standard(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {versions.map((version, index) => (
                <div key={index} className="change-log__item">
                  <p className="change-log__version">v{version.version}</p>
                  {version.logs?.map((log, index) => (
                    <div key={index} className="change-log__scope">
                      {log.scope && (
                        <p className="change-log__scope-name">{log.scope}</p>
                      )}
                      <ul className="change-log__changes">
                        {log.changes.map((change, index) => (
                          <li key={index} className="change-log__change">
                            {change}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  tog_standard();
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
                style={{background: '#212227', border:0}}
              >
                Close
              </button>
            </div>
          </Modal>
    </React.Fragment>
  )
}

export default Footer
