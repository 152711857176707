// CountdownTimer.js
import React, { useState, useEffect, useRef } from 'react';

const CountdownTimer = ({ isRunning }) => {
  const [countdown, setCountdown] = useState(10);
  const countdownRef = useRef();

  useEffect(() => {
    if (isRunning) {
      countdownRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            return 10; // Resetuj licznik do 10
          }
        });
      }, 1000);
    } else {
      clearInterval(countdownRef.current);
    }

    return () => clearInterval(countdownRef.current);
  }, [isRunning]);


  return (
    <div>{countdown}</div>
  );
};

export default CountdownTimer;